import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EnumModel } from '../../../../../models/shared/enum-model';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { PromoCodeGenerateModel } from '../../../../../models/promo-code/promo-code-generate.model';
import { RateScheduleBaseModel } from '../../../../../models/rate-schedule/rate-schedule-base.model';
import { PromoCodesService } from '../promo-codes.service';
import { AuthTokenService } from '../../../../../shared/services/auth-token.service';
import { SaleItemModel } from '../../../../../models/cost-center/sale-item.model';
import { IncidentalSalesService } from '../../../settings-incidental-sales/incidental-sales.service';
import {
    CategoriesWithSaleItemsGridModel,
    SaleItemGridModel,
} from '../../../../../models/cost-center/categories-with-sale-items.model';
import { PromoCodeModel } from '../../../../../models/promo-code/promo-code.model';
import { PromoCodesPermissionsModel } from '../../../../../models/permissions/permissions.model';
import { PermissionsService } from '../../../../../shared/services/permissions.service';
import { PermissionsEnum } from '../../../../../enums/permissions.enum';
import { ToastService } from 'src/app/shared/services/toast.service';
import { ToastEventType } from 'src/app/enums/toast-event-type';

@Component({
    selector: 'app-promo-code-generate',
    templateUrl: './promo-code-generate.component.html',
    styleUrls: ['./promo-code-generate.component.less'],
})
export class PromoCodeGenerateComponent implements OnInit {
    @Input() generatePopup: boolean;
    @Output() generatePopupChange = new EventEmitter<boolean>();
    @Input() editGeneratePopup: boolean;
    @Output() editGeneratePopupChange = new EventEmitter<boolean>();

    @Input() rateSchedules: RateScheduleBaseModel[];
    @Input() promoCodeModelInput: PromoCodeModel;
    @Input() types: EnumModel[];
    @Output() update = new EventEmitter();

    loader = false;
    reward = true;
    promoCodeSaleItem = false;

    dateErrorMessage: string;
    saleItemError = '';

    dateFrom: Date = new Date();
    dateTo: Date = new Date();

    valForm: UntypedFormGroup;
    promoCodeModel = new PromoCodeModel();
    categoriesWithSaleItemsModel: CategoriesWithSaleItemsGridModel[];

    permissionsModel: PromoCodesPermissionsModel;

    theme: number;

    constructor(
        private fb: UntypedFormBuilder,
        private promoCodeService: PromoCodesService,
        private incidentalSalesService: IncidentalSalesService,
        private permissionsService: PermissionsService,
        private toasterService: ToastService
    ) {
        this.theme = AuthTokenService.decodeJwtToken().User.CmsTheme;
        this.permissionsModel = permissionsService.createPermissionsModel(PermissionsEnum.PromoCodes);

        this.valForm = fb.group({
            name: [null, Validators.compose([Validators.required, Validators.maxLength(50)])],
            code: [null, Validators.compose([Validators.required, Validators.maxLength(50)])],
            rateSchedule: [null, Validators.required],
            type: [null, Validators.required],
            reward: [null, Validators.compose([Validators.required])],
            dateFrom: [null, Validators.required],
            dateTo: [null, Validators.required],
        });
    }

    ngOnInit() {
        this.incidentalSalesService.getSaleItemsWithCategories(AuthTokenService.decodeJwtToken().Property.Id).subscribe(
            (response) => {
                this.categoriesWithSaleItemsModel = response;

                if (this.editGeneratePopup) {
                    this.promoCodeModel = JSON.parse(JSON.stringify(this.promoCodeModelInput));
                    this.dateFrom = new Date(this.promoCodeModel.validFrom);
                    this.dateTo = new Date(this.promoCodeModel.validTo);
                    this.valForm.controls['rateSchedule'].setValue(this.promoCodeModel.rateScheduleId);
                    this.valForm.controls['type'].setValue(this.promoCodeModel.discountType);
                    if (this.promoCodeModel.discountType === 'SaleItem') {
                        const label = this.getSaleItem(this.promoCodeModel.discountItem).name;
                        this.valForm.removeControl('reward');
                        this.valForm.addControl('saleItem', new UntypedFormControl(null, Validators.required));
                        this.valForm.controls['saleItem'].setValue(label);
                        this.valForm.updateValueAndValidity();
                        this.reward = false;
                        this.promoCodeModel.reward = null;
                    } else if (this.promoCodeModel.discountType === 'PercentageFee') {
                        this.valForm.removeControl('reward');
                        this.valForm.addControl(
                            'reward',
                            new UntypedFormControl(this.promoCodeModel.reward, Validators.compose([Validators.required]))
                        );
                        this.valForm.updateValueAndValidity();
                    }
                } else if (this.generatePopup) {
                    this.promoCodeModel.reward = 0;
                }

                this.valForm.controls['dateFrom'].setValue(this.dateFrom);
                this.valForm.controls['dateTo'].setValue(this.dateTo);
            },
            (error) => {
                console.log(error);
            }
        );
    }

    getSaleItem(id): SaleItemGridModel {
        for (const item of this.categoriesWithSaleItemsModel) {
            for (const sale of item.saleItems) {
                if (sale.id === id) {
                    return sale;
                }
            }
        }
    }

    validateRangeOfDates(): boolean {
        if (this.dateFrom > this.dateTo) {
            this.dateErrorMessage = 'Date from must be less or equal than date to.';
            return true;
        }
        this.dateErrorMessage = '';
        return false;
    }

    setDiscountType(e) {
        if (e.itemData !== null) {
            const type: EnumModel = e.itemData;
            if (type.value === 'SaleItem') {
                this.reward = false;
                this.promoCodeModel.reward = null;
                this.valForm.removeControl('reward');
                this.valForm.addControl('saleItem', new UntypedFormControl(null, Validators.required));
                this.valForm.updateValueAndValidity();
            } else if (type.value === 'PercentageFee') {
                this.reward = true;
                this.valForm.removeControl('saleItem');
                this.valForm.removeControl('reward');
                this.valForm.addControl('reward', new UntypedFormControl(0, Validators.compose([Validators.required])));
                this.valForm.updateValueAndValidity();
                this.promoCodeModel.reward = 0;
                this.promoCodeModel.discountItem = null;
            } else {
                this.reward = true;
                this.valForm.removeControl('saleItem');
                this.valForm.removeControl('reward');
                this.valForm.addControl('reward', new UntypedFormControl(0, Validators.compose([Validators.required])));
                this.valForm.updateValueAndValidity();
                this.promoCodeModel.reward = 0;
                this.promoCodeModel.discountItem = null;
            }
        }
    }

    submitGenerateCode(e) {
        e.stopPropagation();

        for (const c in this.valForm.controls) {
            this.valForm.controls[c].markAsTouched();
        }

        if (this.valForm.valid && !this.validateRangeOfDates()) {
            this.loader = true;
            this.promoCodeModel.propertyId = AuthTokenService.decodeJwtToken().Property.Id;
            this.promoCodeModel.validFrom = this.dateFrom.toDateString();
            this.promoCodeModel.validTo = this.dateTo.toDateString();

            this.promoCodeService.checkActivePromoCodesForDateRange(this.promoCodeModel).subscribe(
                    (response) => {
                        if (response) {
                            this.toasterService.showToast('Code exists', 'This code already exists with an overlapping date range.  Each code should have a unique date range.', null, ToastEventType.ErrorAlert, true, 10000);
                            this.loader = false;
                        } else {
                            if (this.generatePopup) {
                                this.promoCodeService.generatePromoCodes(this.promoCodeModel).subscribe(
                                    (response) => {
                                        this.loader = false;
                                        this.update.emit();
                                        this.close();
                                    },
                                    (error) => {
                                        this.loader = false;
                                        console.log(error);
                                    }
                                );
                            } else if (this.editGeneratePopup) {
                                this.promoCodeService.updatePromoCode(this.promoCodeModel).subscribe(
                                    (response) => {
                                        this.loader = false;
                                        this.update.emit();
                                        this.close();
                                    },
                                    (error) => {
                                        this.loader = false;
                                        console.log(error);
                                    }
                                );
                            }
                        }
                    },
                    (error) => {
                        console.log(error);
                    }
                );

            
        }
    }

    setSaleItem(saleItem: SaleItemGridModel) {
        this.valForm.controls['saleItem'].setValue(saleItem.name);
        this.promoCodeModel.discountItem = saleItem.id;
        this.promoCodeModel.reward = null;
        this.saleItemError = '';
    }

    openSaleItems() {
        this.promoCodeSaleItem = true;
    }

    close() {
        this.generatePopupChange.emit(false);
        this.editGeneratePopupChange.emit(false);
    }
}
