<div class="promo-codes-wrapper flex">
    <div class="custom-box" [ngClass]="[theme == 2 ? 'dark-theme' : '']">
        <h2>Promo Codes</h2>

        <div class="filter-wrapper flex">
            <div class="form-item">
                <div class="custom-label">Type</div>
                <dx-select-box
                    class="custom-select"
                    [dataSource]="promoCodePDModel.types"
                    valueExpr="value"
                    displayExpr="name"
                    [(value)]="promoCodeSearchModel.type"
                >
                </dx-select-box>
            </div>
            <div class="form-item">
                <div class="custom-label">Rate schedule</div>
                <dx-select-box
                    class="custom-select"
                    valueExpr="id"
                    [(value)]="promoCodeSearchModel.rateScheduleId"
                    displayExpr="name"
                    [dataSource]="promoCodePDModel.rateSchedules"
                >
                </dx-select-box>
            </div>
            <div class="form-item">
                <div class="custom-label">Activity</div>
                <dx-select-box
                    valueExpr="value"
                    [(value)]="promoCodeSearchModel.status"
                    displayExpr="name"
                    class="custom-select"
                    [dataSource]="promoCodePDModel.statuses"
                >
                </dx-select-box>
                <!--<div class="custom-label">Valid from</div>
          <dx-date-box
            [(value)]="dateFrom"
            type="date"
            [displayFormat]="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
          >
          </dx-date-box>-->
            </div>
            <!--<div class="form-item"></div>-->
            <div class="form-item flex">
                <div class="custom-label empty-label"></div>
                <button type="button" class="custom-button blue" (click)="searchCodes()">Search</button>
                <!--<div class="custom-label">Valid to</div>
          <dx-date-box
            [(value)]="dateTo"
            type="date"
            [displayFormat]="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
          >
          </dx-date-box>
        <span>{{ dateErrorMessage }}</span>-->
            </div>
            <!--<div class="form-item"></div>-->
        </div>

        <dx-data-grid
            [dataSource]="promoCodePDModel.promoCodes"
            [allowColumnResizing]="true"
            [(selectedRowKeys)]="selectedRows"
            class="promo-codes-grid"
            [columns]="[
                { dataField: 'name', caption: 'Name', allowSorting: true },
                { dataField: 'code', caption: 'Code', allowSorting: true },
                { dataField: 'rateScheduleName', caption: 'Rate Schedule', allowSorting: true },
                { dataField: 'validFrom', cellTemplate: 'validFrom', caption: 'Valid from', allowSorting: true },
                { dataField: 'validTo', cellTemplate: 'validTo', caption: 'Valid to', allowSorting: true },
                { dataField: 'discountType', caption: 'Type', allowSorting: true },
                { dataField: 'status', cellTemplate: 'status', caption: 'Status', allowSorting: true, width: 150 },
                { name: 'edit', cellTemplate: 'edit', allowSorting: false, width: '50' }
            ]"
        >
            <div *dxTemplate="let v of 'validFrom'">
                {{ v.data.validFrom | date: 'dd MMM yyyy' }}
            </div>
            <div *dxTemplate="let v of 'validTo'">
                {{ v.data.validTo | date: 'dd MMM yyyy' }}
            </div>
            <div *dxTemplate="let v of 'status'">
                <dx-select-box
                    [dataSource]="statuses"
                    [value]="v.data.status"
                    valueExpr="value"
                    displayExpr="name"
                    (onValueChanged)="changeStatus($event, v.data)"
                    [disabled]="!permissionsModel.formButtonEnabled"
                ></dx-select-box>
            </div>
            <div *dxTemplate="let v of 'edit'">
                <i title="{{ permissionsModel.formButtonEnabled ? 'Edit' : 'View' }}" class="ml-10 fas fa-pen-to-square fa-icon gray fa-icon-small" (click)="openEditCode(v.data); $event.stopPropagation()"></i>
            </div>
            <dxo-selection showCheckBoxesMode="always" [allowSelectAll]="true" mode="multiple"> </dxo-selection>
            <dxo-scrolling mode="standard"></dxo-scrolling>
            <dxo-paging [pageSize]="10"></dxo-paging>
            <dxo-pager [showNavigationButtons]="true" [showPageSizeSelector]="true" [showInfo]="true"> </dxo-pager>
        </dx-data-grid>
        <div class="button-wrapper">
            <!--<button class="custom-button" [disabled]="selectedRows.length == 0" (click)="deactivate()">Deactivate</button>-->
            <button
                type="button"
                (click)="openGenerateRange()"
                class="custom-button blue"
                *ngIf="permissionsModel.create"
            >
                Add new code
            </button>
            <button
                type="button"
                class="custom-button orange"
                (click)="exportGrid()"
                *ngIf="permissionsModel.exportToExcel"
            >
                Export
            </button>
        </div>
    </div>
</div>

<app-promo-code-generate
    *ngIf="generatePopup"
    [(generatePopup)]="generatePopup"
    [rateSchedules]="rateSchedules"
    [types]="types"
    (update)="searchCodes()"
>
</app-promo-code-generate>
<app-promo-code-generate
    *ngIf="editGeneratePopup"
    [(editGeneratePopup)]="editGeneratePopup"
    [rateSchedules]="rateSchedules"
    [types]="types"
    [promoCodeModelInput]="promoCodeModel"
    (update)="searchCodes()"
>
</app-promo-code-generate>

