import { Injectable } from '@angular/core';
import { HttpRequestService } from '../../../../shared/services/http-request.service';
import { environment } from '../../../../../environments/environment';
import { PromoCodeSearchModel } from '../../../../models/promo-code/promo-code-search.model';
import { PromoCodeModel } from '../../../../models/promo-code/promo-code.model';
import { ActivateCodeRequestModel } from '../../../../models/promo-code/activate-code-request.model';

@Injectable()
export class PromoCodesService {
    private getPromoCodesUrl = environment.apiUrl + '/PromoCode/GetPromoCodes';
    private generatePromoCodesUrl = environment.apiUrl + '/PromoCode/InsertPromoCode';
    private updatePromoCodeUrl = environment.apiUrl + '/PromoCode/UpdatePromoCode';
    private deactivatePromoCodesUrl = environment.apiUrl + '/PromoCode/DeactivatePromoCode';
    private activatePromoCodesUrl = environment.apiUrl + '/PromoCode/ActivatePromoCode';
    private checkActivePromoCodesForDateRangeUrl = environment.apiUrl + '/PromoCode/CheckActivePromoCodesForDateRange';

    constructor(private http: HttpRequestService) {}

    getPromoCodes(model: PromoCodeSearchModel) {
        return this.http.post(this.getPromoCodesUrl, model);
    }

    generatePromoCodes(model: PromoCodeModel) {
        return this.http.post(this.generatePromoCodesUrl, model, true);
    }

    updatePromoCode(model: PromoCodeModel) {
        return this.http.put(this.updatePromoCodeUrl, model, true);
    }

    deactivatePromoCodes(codeId: number) {
        return this.http.get(this.deactivatePromoCodesUrl + '?codeId=' + codeId, true);
    }

    activatePromoCodes(model: ActivateCodeRequestModel) {
        return this.http.post(this.activatePromoCodesUrl, model, true);
    }

    checkActivePromoCodesForDateRange(model: PromoCodeModel) {
        return this.http.post(this.checkActivePromoCodesForDateRangeUrl, model, false);
    }
}
